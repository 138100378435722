import React, { useState } from "react";

const App = () => {
  const portfolioItems = [
    {
      thumb: "thumb1.jpg",
      url: "V8oKDOEU3ZY",
      name: "MVP Summit Bumpers",
    },
    { thumb: "thumb2.jpg", url: "HXX2qhOB41I", name: "Custom Team Templates" },
    {
      thumb: "thumb3.jpg",
      url: "3X2RezyHeME",
      name: "Azure - What is Data Explorer",
    },
    { thumb: "thumb4.jpg", url: "yAWY1-zyG98", name: "What is Bookings" },
    { thumb: "thumb5.jpg", url: "DNctRTU1ou0", name: "DEVIntersection Bumper" },
    {
      thumb: "thumb6.jpg",
      url: "wzw7zb1YZBE",
      name: "Tophatter - The Showdown",
    },
    { thumb: "thumb7.jpg", url: "lZZkR-dcYiA", name: "KuCoin Play Explainer" },
    { thumb: "thumb8.jpg", url: "1722ctDLLzA", name: "Modular Earphones" },
    { thumb: "thumb9.jpg", url: "9EqiokMuNPE", name: "Boid" },
    { thumb: "thumb10.jpg", url: "o_AGRAza22I", name: "Amazon Blink" },
    { thumb: "thumb11.jpg", url: "0VvUIF7ATA0", name: "NICE" },
    { thumb: "thumb12.jpg", url: "L0sNe8qvdPU", name: "iCount" },
  ];

  const [openVideo, setOpenVideo] = useState(false);
  const [video, setVideo] = useState(false);

  const handleOpenVideo = (url) => {
    setVideo(url);
    setOpenVideo(true);
  };

  return (
    <React.Fragment>
      <div className="container">
        <section>
          <h1>Hey! I’m Luis</h1>
          <p>
            I’m a freelance motion designer based in sunny Merida, Mexico. For
            the past 10 years I have been fortunate enough to work with more
            than 120 brands and startups from 22 different countries.
          </p>
          <p>
            I love fresh ideas, ambitious challenges and energizing
            conversations.
          </p>
          <p>Feel free to get in touch or take a look at my past work below.</p>
        </section>
        <section>
          <h2>Reel</h2>
          <img
            style={{
              width: "100%",
              borderRadius: "10px",
              marginTop: "1rem",
              cursor: "pointer",
            }}
            src="/images/reelthumb.jpg"
            alt="Reel"
            onClick={() => handleOpenVideo("Z2ncUT08A-c")}
          />
        </section>
        <section>
          <h2>Recent works</h2>
          <div className="grid">
            {portfolioItems.map((item, i) => (
              <div
                key={i}
                className="portfolio-item"
                onClick={() => handleOpenVideo(item.url)}
              >
                <img src={`/images/${item.thumb}`} alt={item.thumb} />
              </div>
            ))}
          </div>
        </section>
        <section>
          <h2>Thank you!</h2>
          <p>
            email:{" "}
            <a
              href="mailto:luislopezteams@outlook.com"
              style={{ textDecoration: "none", color: "#0078d4" }}
            >
              luislopezteams@outlook.com
            </a>
          </p>
        </section>
      </div>
      {openVideo && (
        <div className="modal" onClick={() => setOpenVideo(false)}>
          <div className="video-size">
            <div className="video-container">
              <iframe
                style={{ borderRadius: "10px" }}
                src={`https://www.youtube.com/embed/${video}`}
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default App;
